import React from "react";
import Layout from "../components/Layout";

export default function Redirect() {
  if (typeof window !== `undefined`) {
    window.location.href = `https://play.google.com/store/apps/details?id=com.drivit&referrer=${window.location.href
      .split("/")
      .pop()}`;
  }

  return (
    <Layout>
      <p>Redirecting...</p>
    </Layout>
  );
}
